import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  const user = ref({})

  try {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      user.value = JSON.parse(savedUser);
    }
  } catch (error) {
    console.error('Failed to load user from localStorage', error);
  }

  const setUser = (newUser) => {
    user.value = newUser;
    localStorage.setItem('user', JSON.stringify(newUser));
  }

  const userRole = computed(() => user.value.role);
  const isSuperAdmin = computed(() => user.value.role === 'ROLE_SUPER_ADMIN');
  const isOrganization = computed(() => user.value.role === 'ROLE_ORGANIZATION' || isSuperAdmin.value);
  const isUser = computed(() => user.value.role === 'ROLE_USER' || isSuperAdmin.value);
  const isLoggedIn = computed(() => user.value.id !== undefined);
  const isActive = computed(() => user.value.active);
  const initials = computed(() => {
    if (user.value.firstName && user.value.lastName) {
      return user.value.firstName.charAt(0).toUpperCase() + user.value.lastName.charAt(0).toUpperCase();
    }
    return '--';
  });

  return { user, setUser, userRole, isLoggedIn, initials, isSuperAdmin, isOrganization, isUser, isActive}
})
