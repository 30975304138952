<template>
  <BToastOrchestrator />
  <TopBar v-if="showTopBar" />
  <RouterView />

</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { RouterLink, RouterView, useRouter, useRoute } from 'vue-router'
import ConnectionState from '@/components/ConnectionState.vue';
import TopBar from './components/TopBar.vue';
import { useUserStore } from '@/stores/user';

import { ref, watch } from 'vue';
// import io from 'socket.io-client';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const showTopBar = ref(false);

watch(
  () => route.meta.layout,
  (layout) => {
    showTopBar.value = layout !== 'clean';
  },
  { immediate: true }
);

onMounted(() => {
  if (!localStorage.getItem('colorMode')) {
    document.documentElement.setAttribute('data-bs-theme', 'light')
    localStorage.setItem('colorMode', 'light');
  } else {
    document.documentElement.setAttribute('data-bs-theme', localStorage.getItem('colorMode'))
  }
  // socket.value = io(socketapiurl);
  // socket.value.on('updateEventList', list);
});

/* onUnmounted(() => {
  if (socket.value) {
    socket.value.off('actualizarListado', list);
    socket.value.disconnect();
  }
}); */

</script>
