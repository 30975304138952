import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'clean'
      },
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgotpassword',
      meta: {
        layout: 'clean'
      },
      component: () => import('../views/ForgotPasswordView.vue')
    },
    {
      path: '/changepassword/:passwordtoken',
      name: 'changepassword',
      meta: {
        layout: 'clean'
      },
      component: () => import('../views/ChangePasswordView.vue')
    },
    {
      path: '/',
      name: 'home',
      meta: {
        layout: 'clean'
      },
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/clips/:page?',
      name: 'clips',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER'] 
      },
      component: () => import('../views/ClipsView.vue'),
      props: true
    },
    {
      path: '/clip',
      name: 'clipRedirect',
      redirect: '/clips'
    },
    {
      path: '/clip/:id',
      name: 'transcription',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER'] 
      },
      component: () => import('../views/TranscriptionView.vue'),
      props: true
    },
    {
      path: '/users',
      name: 'users',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION'] 
      },
      component: () => import('../views/UsersView.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION', 'ROLE_USER'] 
      },
      component: () => import('../views/SettingsView.vue')
    },
    {
      path: '/admin/users/:page?',
      name: 'adminusers',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN'] 
      },
      component: () => import('../views/AdminUsersView.vue'),
    },
    {
      path: '/admin/clips/:page?',
      name: 'adminclips',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN'] 
      },
      component: () => import('../views/AdminClipsView.vue'),
      props: true
    },
    {
      path: '/admin/use',
      name: 'adminuse',
      meta: { 
        requiresAuth: true, 
        roles: ['ROLE_SUPER_ADMIN'] 
      },
      component: () => import('../views/AdminUseView.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  
  const token = localStorage.getItem('token');

  //TBD: si estoy accediendo a login y ya estoy logueado, redirigir a clips
  /* if (to.name === 'login' && token) {
    next({ path: '/clips' });
  } */
  

  // Verificar si la ruta requiere autenticación y por tanto el user tiene que estar activo
  if (to.meta.requiresAuth) {
    // ruta requiere autenticación
    if (!token) {
      // no hay token, a login
      next({ path: '/login' });
    } else {
      // hay token, verificar si el user está activo
      const user = JSON.parse(localStorage.getItem('user'));
      const roles = to.meta.roles || [];
      const hasRole = roles.length === 0 || roles.includes(user.role);
      const isActive = user.active || false;
      if (!isActive) {
        next({ path: '/' });
      } else {
        // verificar si el user tiene el rol necesario
        if (!hasRole) {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  } else {
    // Si la ruta no requiere autenticación, se permite el acceso
    next();
  }
});

export default router